import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import ButtonSolid from "../Button/ButtonSolid";

const CTA = ({
  heading,
  headingLevel,

  className,
}) => {
  const HeadingTag = headingLevel || "h2";

  return (
    <section
      className={`relative overflow-hidden py-20 md:py-26 ${className || ""}`}
    >
      <div className="absolute left-0 top-0 hidden h-full w-full md:block">
        <StaticImage
          src="../../images/0.0 Repeating Modules/CTA/CTA-Desktop.jpg"
          loading="lazy"
          className="h-full"
        />
      </div>
      <div className="absolute left-0 top-0 h-full w-full md:hidden">
        <StaticImage
          src="../../images/0.0 Repeating Modules/CTA/CTA-mobile.jpg"
          loading="lazy"
          className="h-full"
        />
      </div>

      <div className="container">
        <div className="relative mx-auto max-w-[800px] text-center">
          <HeadingTag className="text-white">
            {heading || ["Let's Connect"]}
          </HeadingTag>
          <p className="text-white md:mb-8">
            Our goal is to respond to your request within two business days. If
            your request does not meet our investment criteria, we will make a
            concerted effort to refer you to other sources of capital known to
            us that may be a fit.
          </p>
          <ButtonSolid modal="modal-contact" text="Contact Us" />
        </div>
      </div>
    </section>
  );
};

export default CTA;

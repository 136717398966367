import React from "react";
import { graphql } from "gatsby";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Logos from "../components/Repeating/Logos";
import ValueProps from "../components/Repeating/ValueProps";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import ButtonUnderline from "../components/Button/ButtonUnderline";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Assurance Capital | Private Investment Firm"
        description="Do you own a profitable company and want to grow even more? Assurance Capital is a private investment firm with customized growth solutions. Contact us today."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="relative mb-14 overflow-hidden pt-24 pb-14 md:mb-20 md:pt-48 md:pb-52">
        <div className="absolute left-0 top-0 hidden h-full w-full md:block">
          <StaticImage
            src="../images/1.0 Homepage/1.0 hero-homepage.jpg"
            loading="eager"
            className="h-full"
          />
        </div>

        <div className="absolute left-0 top-0 h-full w-full md:hidden">
          <StaticImage
            src="../images/1.0 Homepage/1.0 hero - mobile.jpg"
            loading="eager"
            className="h-full"
          />
        </div>
        <div className="container">
          <div className="relative mx-auto max-w-[800px] text-center">
            <p className="font-heading text-mobile-7xl font-extrabold uppercase text-white md:text-7xl">
              Assurance Capital
            </p>
            <p className="text-lg text-white md:mb-8 md:text-xl">
              We’re a private investment firm providing $3 to $20 million of
              customized growth solutions to profitable, lower-middle-market
              companies nationwide.
            </p>
            <ButtonSolid modal="modal-contact" text="Contact Us" />
          </div>
        </div>
      </section>

      <ValueProps />

      <section className="relative mb-20 overflow-hidden bg-primary-500 py-20 md:mb-32 md:bg-transparent md:py-40">
        <div className="absolute left-0 top-0 hidden h-full w-full md:block">
          <StaticImage
            src="../images/1.0 Homepage/3.0 Investment Criteria.jpg"
            loading="eager"
            className="h-full"
          />
        </div>
        <div className="container">
          <div className="relative text-center md:max-w-[560px] md:text-left">
            <h2 className="mb-10 text-white md:mb-12 md:text-typography-heading">
              Investment Criteria
            </h2>

            <div className="mb-12 md:mb-14">
              <p className="heading-six text-white md:text-typography-heading">
                Assurance Mezzanine
              </p>
              <p className="text-sm text-white/80 md:text-base md:text-typography-body">
                Assurance Mezzanine Fund is a private debt fund providing $3 to
                $20 million of customized growth solutions to profitable,
                lower-middle-market companies.
              </p>
              <ButtonSolid
                href="/mezzanine-fund/"
                text="Assurance Mezzanine"
                className="min-w-[240px]"
              />
            </div>

            <div>
              <p className="heading-six text-white md:text-typography-heading">
                Assurance Growth partners
              </p>
              <p className="text-sm text-white/80 md:text-base md:text-typography-body">
                Assurance Growth Partners is a private equity fund that makes
                control equity investments in private, lower middle market, U.S.
                based companies.
              </p>
              <ButtonSolid
                href="/growth-partners/"
                text="Assurance Growth Partners"
                className="min-w-[240px]"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-12 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <StaticImage
                src="../images/1.0 Homepage/2.0 intro-homepage.png"
                loading="lazy"
              />
            </div>
            <div className="order-1 md:order-2">
              <h1>Who We Invest With</h1>
              <p>
                Assurance Capital was created by former industry executives and
                experienced investors who highly value their relationships with
                management teams. We look to invest our funds in established
                companies operated by experienced and proven management teams
                with a history of building enterprise value.
              </p>
              <ButtonUnderline href="/portfolio/" text="View Our Portfolio" />
            </div>
          </div>
        </div>
      </section>

      <Logos />

      <section className="relative pb-24 md:pb-32">
        <div className="absolute left-0 bottom-0 w-full">
          <StaticImage
            src="../images/1.0 Homepage/About/background.jpg"
            loading="lazy"
          />
        </div>
        <div className="container relative">
          <div className="grid items-center gap-y-12 md:grid-cols-1 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 text-center md:order-1 md:mb-14">
              <h2>About Us</h2>
              <p>
                The Assurance Capital team is composed of our best and brightest
                to assure the success of any venture.
              </p>
              <ButtonUnderline href="/team/" text="Meet the Team" />
            </div>
            <div className="order-2 md:order-2">
              <div className="grid grid-cols-2 gap-4 md:grid-cols-5">
                {data.teamMembers.edges.slice(0, 0).map(({ node }, i) => {
                  return (
                    node.headshot && (
                      <a
                        href={`/${node.slug.current}/`}
                        className="z-0 block overflow-hidden rounded-full"
                        key={i}
                      >
                        <GatsbyImage
                          image={node.headshot.asset.gatsbyImageData}
                          loading="lazy"
                          width={176}
                        />
                      </a>
                    )
                  );
                })}

                {data.teamMembers.edges.slice(0).map(({ node }, i) => {
                  return (
                    node.headshot && (
                      <a
                        href={`/${node.slug.current}/`}
                        className="z-0 block overflow-hidden text-center "
                        key={i}
                      >
                        <GatsbyImage
                          image={node.headshot.asset.gatsbyImageData}
                          className="rounded-full"
                          loading="lazy"
                          width={176}
                        />
                      </a>
                    )
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>

      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
    teamMembers: allSanityTeamMember(sort: { fields: [order], order: ASC }) {
      edges {
        node {
          slug {
            current
          }
          headshot {
            asset {
              gatsbyImageData(layout: CONSTRAINED, width: 176)
            }
          }
        }
      }
    }
  }
`;

export default Page;

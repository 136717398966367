import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import tw from "twin.macro";

import { mapEdgesToNodes } from "../../lib/helpers";
import Slider from "../Slider/SliderLogos";

const StyledSlider = styled.div`
  .slick-slide {
    ${tw`text-center`}
    > div {
      ${tw`flex items-center justify-between space-x-4`}
    }
  }
`;

const Accolades = ({ className }) => {
  const data = useStaticQuery(graphql`
    {
      portfolio: allSanityPortfolio(sort: { fields: [order], order: ASC }) {
        edges {
          node {
            grayscaleLogo {
              asset {
                gatsbyImageData(layout: CONSTRAINED, width: 120)
              }
            }
          }
        }
      }
    }
  `);

  const portfolioNodes = (data || {}).portfolio
    ? mapEdgesToNodes(data.portfolio)
    : [];

  return (
    <section className={`overflow-x-hidden ${className || "mb-20 md:mb-32"}`}>
      <div className="container">
        <StyledSlider className="relative">
          <Slider>
            {portfolioNodes.map(
              (item, i) =>
                item.grayscaleLogo && (
                  <div key={i}>
                    <GatsbyImage
                      image={item.grayscaleLogo.asset.gatsbyImageData}
                      loading="lazy"
                    />
                  </div>
                )
            )}
          </Slider>
        </StyledSlider>
      </div>
    </section>
  );
};

export default Accolades;

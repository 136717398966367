import React, { Component } from "react";
import Slick from "react-slick";
import styled from "@emotion/styled";
import tw from "twin.macro";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const StyledSlider = styled.div`
  .slick-list {
    ${tw`overflow-visible`}
  }
  .slick-track {
    ${tw`!flex !items-center`}
  }
  .slick-slide {
    ${tw`!mx-10`}
  }
`;

export default class Slider extends Component {
  render() {
    const sliderSettings = {
      speed: 4000,
      autoplay: true,
      autoplaySpeed: 0,
      cssEase: "linear",
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      pauseOnHover: false,
      dots: false,
      arrows: false,
    };

    return (
      <StyledSlider>
        <Slick {...sliderSettings}>{this.props.children}</Slick>
      </StyledSlider>
    );
  }
}

import React from "react";
import { useStaticQuery, graphql } from "gatsby";

const ValueProps = ({ headingLevel, className }) => {
  const data = useStaticQuery(graphql`
    {
      icon1: file(
        relativePath: {
          eq: "0.0 Repeating Modules/Value Props/credit-card.svg"
        }
      ) {
        publicURL
      }
      icon2: file(
        relativePath: { eq: "0.0 Repeating Modules/Value Props/fast.svg" }
      ) {
        publicURL
      }
      icon3: file(
        relativePath: { eq: "0.0 Repeating Modules/Value Props/chart.svg" }
      ) {
        publicURL
      }
    }
  `);

  const HeadingTag = headingLevel || "h2";

  const content = [
    {
      icon: data.icon1.publicURL,
      heading: "We Know Business",
      text: "We know what it takes to operate and grow a business. We offer business owners our experience and network contacts.",
    },
    {
      icon: data.icon2.publicURL,
      heading: "We’re Fast and Fair",
      text: "We understand fundraising can be a distraction, so we work hard to make the diligence and closing process fast and fair.",
    },
    {
      icon: data.icon3.publicURL,
      heading: "We Focus On Outcome",
      text: "A great deal for us is where both parties are happy and motivated to help grow the company.",
    },
  ];

  return (
    <section className={`${className || "mb-20 md:mb-32"}`}>
      <div className="container">
        <header className="mx-auto mb-12 max-w-[680px] text-center md:mb-20">
          <HeadingTag>The Value We Bring</HeadingTag>
        </header>

        <div className="relative z-10 grid gap-y-12 md:grid-cols-3 md:gap-x-10 lg:gap-x-20">
          {content.map((content, i) => {
            return (
              <div key={i} className="text-center">
                <img
                  src={content.icon}
                  alt={content.heading}
                  className="mx-auto mb-3"
                />
                <div>
                  <p className="heading-six mb-3">{content.heading}</p>
                  <p className="mb-0">{content.text}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ValueProps;
